import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import "./App.css"
import ABI from "./ABI.json";
import ERC20ABI from "./ERC20ABI.json"

const App = () => {
  
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState('');
  const [contract, setContract] = useState(null);
  const [erc20Contract, setErc20Contract] = useState(null);

  useEffect(() => {
    console.log('a')
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      alert('No web3 provider detected. Please install MetaMask to use this application.');
    }
    console.log('b')

    const contractAddress = '0x37f27ec22939396feb41e50860a2d5477db3a142';
    const contractABI = ABI;

    const contractInstance = new window.web3.eth.Contract(contractABI, contractAddress);
    setContract(contractInstance);

    const erc20ContractAddress = '0xd230e28cB5284E42E282E28C3f69b4146559F2B4';
    const erc20ContractABI = ERC20ABI;

    const erc20Instance = new window.web3.eth.Contract(erc20ContractABI, erc20ContractAddress);
    setErc20Contract(erc20Instance);
    console.log('c')
    window.web3.eth.getAccounts().then((accounts) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
      console.log('d')
    });
  }, []);

  const mintToken = async () => {
    if (!account) {
      alert('Please connect your MetaMask wallet to mint a Zoin token.');
      return;
    }

    try {
      setLoading(true);
      await erc20Contract.methods.approve(contract.options.address, (Number(150n * 10n**18n))).send({ from: account });

      await contract.methods.mint().send({ from: account, value: 0 });

      setLoading(false);
      alert('NFT minted successfully.');
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert('Error minting NFT.');
    }
    
  };

  return (
    <div className="centered-container">
      <div className="minting-page">
        <h1>Mint Your NFT</h1>
        <p>Click Mint to connect MetaMask 🦊 and mint your NFT.</p>
        <button onClick={mintToken} disabled={loading}>
          Mint
        </button>
      </div>
    </div>
  );
};

export default App;
